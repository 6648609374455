import { i18n } from "@/i18n/config";

export const required = (v: string): string | boolean =>
  !!v || i18n.tc("general.RequiredField");
export const email = (v: string): string | boolean =>
  !v ||
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
  i18n.tc("general.EmailMustBeValid");

export const generalFiscalCode = (v: string): string | boolean =>
  !v ||
  // /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/.test(v) || // OLD, NON GESTITA OMOCODIA! (Trovata su http://blog.marketto.it/2016/01/regex-validazione-codice-fiscale-con-omocodia/)
  /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i.test(
    v
  ) ||
  i18n.tc("general.FiscalCodeMustBeValid");
export const iban = (v: string): string | boolean =>
  !v ||
  /^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$/gim.test(
    v
  ) ||
  i18n.tc("general.InvalidIBAN");
export const sameValue = (
  v: string,
  vSame: string,
  errorMessage: string
): string | boolean => !v || !vSame || v === vSame || errorMessage;

export const generalAlert = (
  v: string,
  vShow: boolean,
  errorMessage: string
): string | boolean => !v || vShow === true || errorMessage;

export const maxLenght = (v: string): string | boolean =>
  /^.{1,70}$/.test(v) || i18n.tc("general.FiscalCodeMustBeValid");
